import React, { useLayoutEffect, useState } from "react"
import { useFormik } from "formik"
import {
  StyledForm,
  StyledFieldset,
  StyledLabel,
  StyledLabelHeader,
  StyledFormElementWrapper,
  StyledSelectWrapper,
  StyledSelect,
  StyledOption,
} from "../styledComponents/contact"

const FormEventRegisterDates = ({sendDataToParent}) => {
  const [isSuccessMessage, setIsSuccessMessage] = useState(false) // manage is success message state
  const [messageSent, setMessageSent] = useState(false) // manage sent message state
  const [isFormValid, setIsFormValid] = useState(false)


  // Validation
  const validate = (values, url, event) => {
    const errors = {}
    Object.keys(errors).length ? setIsFormValid(false) : setIsFormValid(true)
    return errors
  }
  const scrollToTop = () => {
    console.log('scrollToTop')
    document.getElementById('single-event').scrollIntoView({
      behavior: 'smooth'
    });
  }

  const handleChange = (e) => {
    sendDataToParent(e.target.value)
    scrollToTop()
  }

  // use useFormik hook using object destructuring assignment to extract helpful methods
  const {
    handleBlur,
    isSubmitting,
    values,
    handleSubmit,
    errors,
    touched,
  } = useFormik({
    initialValues: {
      eventdate: "",
    },
    validate,
    onSubmit: (
      {
        eventdate,
      },
      { setSubmitting, resetForm }
    ) => {
      setSubmitting(true)      
    },
  })

  useLayoutEffect(() => {

    // set timeout 3 seconds to remove error/success message.
    setTimeout(() => {
      // this will reset messageSent and isSuccessMessage state

      setMessageSent(false)
      setIsSuccessMessage(false)
    }, 3000)
    // this effect function will be dispatched when isSuccessMessage or messageSent changes its state
  }, [isSuccessMessage, messageSent])

  return (
    <StyledForm onSubmit={handleSubmit}>
      <StyledFieldset>
        <StyledSelectWrapper>
          <StyledFormElementWrapper>
            <StyledLabel htmlFor="eventdate">
              <StyledLabelHeader
                required
                isEmpty={errors.eventdate && touched.eventdate}
                hidden
              >
                Which Quantum-Touch workshop are you interested in?
              </StyledLabelHeader>
              <StyledSelect
                defaultValue="default"
                id="eventdate"
                name="eventdate"
                onChange={handleChange}
                onBlur={handleBlur}
                hasBackground
                className="hasBackgroundColor"
                required
              >
                <StyledOption value="default" disabled hidden>Select workshop?</StyledOption>
                <StyledOption value="cG9zdXoxMTIyNw==">QT Energy Boost - September 30th - Online</StyledOption>
                <StyledOption value="cG9zdDoxMTIzMw==">QT Level 1 - Oct 8th - Online</StyledOption>
                <StyledOption value="vG9zdQoxMTIcMw==">QT Becoming More - Oct 16th - Cancun, Mexico</StyledOption>
                <StyledOption value="hV9zcDoxMTeyOI==">QT Self Created Health - Oct 28th - Online</StyledOption>
                <StyledOption value="xF9zcDoxMTFyOQ==">QT Level 2 - Dec 3rd - Online</StyledOption>
                <StyledOption value="xF9zcDoxMTFyOZ==">QT Manifesting Miracles - Jan 6th - Online</StyledOption>
              </StyledSelect>
            </StyledLabel>
            {errors.eventdate && touched.eventdate ? (
              <span className="error-message">{errors.eventdate}</span>
            ) : null}
          </StyledFormElementWrapper>
        </StyledSelectWrapper>
      </StyledFieldset>
    </StyledForm>
  )
}

export default FormEventRegisterDates
